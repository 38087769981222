<template>
    <div
        ref="tooltip"
        class="tooltip-component"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        :data-bs-html="true"
        :data-bs-title="purifyText"
    >
        <slot></slot>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap"
import DOMPurify from "dompurify"

export default {
    name: "Tooltip",
    props: {
        text: {
            type: String,
            default: "",
        },

        vHtmlText: {
            type: String,
            default: "",
        },

        displayMethod: {
            type: String,
            default: "hover",
        },
    },
    data() {
        return {
            hover: false,
        }
    },

    computed: {
        purifyText() {
            return DOMPurify.sanitize(this.vHtmlText)
        },
    },

    mounted() {
        this.tooltipInstance = new Tooltip(this.$refs.tooltip, {
            trigger: this.displayMethod,
            html: true,
            title: this.purifyText ? this.purifyText : this.text,
        })

        if (this.displayMethod === "click") {
            document.addEventListener("click", this.handleDocumentClick)
        }
    },

    beforeUnmount() {
        if (this.tooltipInstance) {
            this.tooltipInstance.dispose()
        }
    },

    methods: {
        handleDocumentClick(event) {
            // Check if the click is outside the tooltip component
            if (
                this.$refs.tooltip &&
                !this.$refs.tooltip.contains(event.target)
            ) {
                this.tooltipInstance.hide()
            }
        },
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";
a {
    color: $blue-neutral;
}

.tooltip-component {
    position: relative;
    display: inline-block;

    .azerty {
        visibility: hidden;
        position: absolute;
        bottom: 160%;
        left: -5.2em;
        padding: 0.2em 0.5em;
        transform: translateX(-25%);
        width: max-content;
        z-index: 1;
        background-color: $white;
        color: $blue-neutral;
        text-align: center;
        padding: 5px 15px;
        border-radius: 5px;
        box-shadow: 0px 0 15px 0 rgb(0 0 0 / 20%);
        font-family: $font-avenir-roman;
        font-size: $small;
    }
    .azerty::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 50%;
        border-style: solid;
        border-width: 5px;
        border-color: $white transparent transparent transparent;
    }
}

.tooltip-component:hover {
    .azerty {
        visibility: visible;
    }
}

.tooltip {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0px 0 15px 0 rgb(0 0 0 / 20%);
}

.tooltip-inner {
    background-color: transparent;
    color: $blue-neutral;
    font-size: small;
    font-family: $font-avenir-roman;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-top-color: $white !important;
}
</style>
